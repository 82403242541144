import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FileNameGeneratorDialogData } from '@app/workflow/components/job-configuration/job-input-form/file-name-generator-dialog/file-name-generator-dialog.service';

@Component({
    template: `
        <sphere-file-name-generator
            [fileNameInput]="data.fileName"
            [name]="data.taskName"
            [category]="data.taskCategory"
            [isFileNameMatched]="data.isFileNameMatched"
            (fileNameChange)="patchFileName($event)"
            (isFileNameFormValid)="isFileNameFormValid($event)"
        ></sphere-file-name-generator>

        <div class="file-name__actions">
            <button mat-button (click)="closeDialog()">CANCEL</button>
            <button mat-raised-button color="primary" (click)="closeDialog(fileNameOutput)" [disabled]="isSubmitBtnDisabled">SAVE</button>
        </div>
    `,
    styles: [
        `
            :host {
                display: block;
                height: 720px;
            }

            .file-name__actions {
                display: flex;
                justify-content: flex-end;
                align-items: flex-end;
                gap: 10px;
                padding: 20px;
            }
        `
    ],
    standalone: false
})
export class FileNameGeneratorDialogComponent {
    fileNameOutput: string;
    isSubmitBtnDisabled = false;
    constructor(
        private dialogRef: MatDialogRef<FileNameGeneratorDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: FileNameGeneratorDialogData
    ) {}

    patchFileName(fileName: string) {
        this.fileNameOutput = fileName;
    }

    closeDialog(fileNameOutput?: string) {
        this.dialogRef.close(fileNameOutput);
    }

    isFileNameFormValid(isValid: boolean) {
        this.isSubmitBtnDisabled = !isValid;
    }
}
