import { Component, DestroyRef, inject, OnInit, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FileNameGeneratorDialogService } from '@app/workflow/components/job-configuration/job-input-form/file-name-generator-dialog/file-name-generator-dialog.service';
import { FileNameGeneratorService } from '@app/workflow/components/job-configuration/job-input-form/file-name-generator/file-name-generator.service';
import { FieldType } from '@ngx-formly/core';
import { filter, take, tap } from 'rxjs/operators';

@Component({
    selector: 'sphere-file-name-input',
    template: ` <mat-form-field>
        <mat-label>{{ props.label || 'File name' }}</mat-label>
        <input matInput [formControl]="formControl" type="text" />
        <button matSuffix mat-icon-button (click)="openDialog(formControl.value)" aria-label="Customize your file name">
            <mat-icon matTooltip="Customize your file name.">settings</mat-icon>
        </button>
        <mat-hint>{{ props.description || 'Click on the cog icon on the right to configure the file name.' }}</mat-hint>
    </mat-form-field>`,
    styles: [],
    standalone: false
})
export class FileNameInputComponent extends FieldType implements OnInit {
    private destroyRef = inject(DestroyRef);
    isFileNameMatched = signal<boolean>(true);

    constructor(
        private fileNameGeneratorDialog: FileNameGeneratorDialogService,
        private fileNameGeneratorService: FileNameGeneratorService
    ) {
        super();
    }

    ngOnInit() {
        this.formControl.valueChanges
            .pipe(
                takeUntilDestroyed(this.destroyRef),
                tap(value => {
                    try {
                        const config = this.fileNameGeneratorService.parseInput(value);
                        const { hasDateFormat, dateFormat, isFileNameMatched } = config;
                        let withoutCommas: string = dateFormat;
                        this.isFileNameMatched.set(isFileNameMatched);
                        if (hasDateFormat) {
                            withoutCommas = withoutCommas.replace(/,/gm, '');
                            const fileName = this.fileNameGeneratorService.generateOutputFileNameFromConfig({ ...config, dateFormat: withoutCommas });
                            this.formControl.setValue(fileName, { emitEvent: false });
                        }
                    } catch (e) {
                        // Non tokenized format passthrough.
                    }
                })
            )
            .subscribe();
    }

    openDialog(fileNameInput: string) {
        const name = this.props.getNameFn && typeof this.props.getNameFn === 'function' ? this.props.getNameFn() : 'Name',
            category = this.props.getCategoryFn && typeof this.props.getCategoryFn === 'function' ? this.props.getCategoryFn() : 'Category';
        const isFileNameMatched = this.isFileNameMatched();
        this.fileNameGeneratorDialog
            .openFileNameGeneratorDialog(fileNameInput, name, category, isFileNameMatched)
            .pipe(
                take(1),
                filter(result => !!result),
                tap(fileNameOutput => this.formControl.setValue(fileNameOutput))
            )
            .subscribe();
    }
}
