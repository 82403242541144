import { Component, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { FieldType } from '@ngx-formly/core';
import { MatInput } from '@angular/material/input';
import { map, shareReplay, startWith, withLatestFrom } from 'rxjs/operators';

@Component({
    selector: 'sphere-autocomplete-formly',
    template: `
        <mat-form-field appearance="fill">
            <mat-label>{{ props.label }}</mat-label>
            <input matInput [matAutocomplete]="auto" [formControl]="formControl" [formlyAttributes]="field" />
            <mat-autocomplete #auto="matAutocomplete">
                <ng-container *ngrxLet="options$; let options; let optionsError = $error; let optionsComplete = $complete">
                    <ng-container *ngrxLet="filteredOptions$; let filteredOptions; let e = $error; let c = $complete">
                        <mat-option *ngFor="let option of filteredOptions" [value]="option">
                            {{ option }}
                        </mat-option>
                        <ng-container *ngIf="optionsError">
                            <mat-option [disabled]="true">Error loading</mat-option>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </mat-autocomplete>
            <mat-hint *ngIf="!!props.description">{{ props.description }}</mat-hint>
        </mat-form-field>
    `,
    styles: [``],
    standalone: false
})
export class AutocompleteFormlyComponent extends FieldType implements OnInit {
    @ViewChild(MatInput) formFieldControl: MatInput;

    public options$: Observable<any[]>;
    public filteredOptions$: Observable<any[]>;

    ngOnInit() {
        this.options$ = this.props.filterOptions.pipe(shareReplay(1));
        this.filteredOptions$ = this.formControl.valueChanges.pipe(
            startWith(''),
            withLatestFrom(this.options$),
            map(([value, options]) => this.props.filter(options, value) as any[])
        );
    }
}
